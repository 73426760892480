import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import ItemsPerPage from "../../../components/ItemsPerPage/ItemsPerPage";
import { createSearchParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { SOCIETY_HISTORY_REPORT_ENDPOINT } from "../../../Routing/routes";
import { FaFolderOpen } from "react-icons/fa";
import FileModal from "../../../components/Modal/FileModal";
import Pagination from "../../../components/Pagination/Pagination";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        zIndex: 0,
        color: "#AAAAAA",
        fontSize: "13px",
        padding: "12px 8px 12px 0",
        wordBreak: "break-word",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        borderBottom: "none",
        fontSize: "13px",
        padding: "12px 8px 12px 3px",
        wordBreak: "break-word",
        cursor: "pointer",
        textAlign: "center",
        display: "table-cell", // Default display
        justifyContent: "center",
    },
}));

const SocietyDownloadHistoryTable = ({
    loading,
    data,
    columns,
    pagination = true,
    totalCount,
    totalPages,
    start,
    end,
    currentPage,
    rowsPerPage,
}) => {
    const navigate = useNavigate();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selected, setSelected] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState();
    const [isFolderOpen, setIsFolderOpen] = useState(false);
    const [openModalFileId, setOpenModalFileId] = useState(null);
    const headCells = [
        { id: "Date", label: "Date", width: "140px" },
        { id: "Type", label: "Type", width: "100px" },
        { id: "File Name", label: "File Name", sort: true, width: "280px" },
        { id: "User Id", label: "User Id", sort: true, width: "90px" },
        { id: "User Role", label: "User Role", sort: true, width: "90px" },
        { id: "User Email", label: "User Email", sort: true, width: "90px" },
        { id: "User Action", label: "Action", sort: false, width: "50px" }
    ];
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const handleIconClick = () => {
        setIsFolderOpen(!isFolderOpen); 
        setIsOpenModal(!isOpenModal);  
    };

    const handleSelectAllClick = (event) => {
        const newSelected = event.target.checked
            ? data.map((item) => item.employeeRoleId)
            : [];
        setSelected(newSelected);
    };
    const handleOpenModal = (fileId) => {
        setOpenModalFileId(fileId);
        setIsOpenModal(true);
    };

    const handleClick = (event, id) => {
        const newSelected = selected.includes(id)
            ? selected.filter((selectedId) => selectedId !== id)
            : [...selected, id];
        setSelected(newSelected);
    };

    const handleChangePage = (newPage) => {
        searchParams.delete("page");
        navigate({
            pathname: `${SOCIETY_HISTORY_REPORT_ENDPOINT}`,
            search: `?${createSearchParams([
                ...searchParams.entries(),
                ["page", newPage],
            ])}`,
        });
    };

    const handleChangeRowsPerPage = (value) => {
        if (rowsPerPage !== value) {
            searchParams.delete("pageLimit");
            navigate({
                pathname: `${SOCIETY_HISTORY_REPORT_ENDPOINT}`,
                search: `?${createSearchParams([
                    ...searchParams.entries(),
                    ["pageLimit", value],
                ])}`,
            });
        }
    };

    const isSelected = (id) => selected.includes(id);

    return (
        <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
            <TableContainer
                className={isOpenModal ? "blurred-table" : "border-t border-[#CCC]"}
                style={{
                    transition: "filter 0.3s ease",
                    filter: isOpenModal ? "blur(4px)" : "none",
                }}
                sx={{ minHeight: data?.length ? "500px" : "none" }}
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns?.map((column) => (
                                <TableCellStyled
                                    size="small"
                                    key={column.id + "heading"}
                                    // align={column.numeric ? "right" : "left"}
                                    align="center"
                                    sx={{
                                        width: column.width || "fit-content",
                                        minWidth: column.width || "fit-content",
                                        alignCenter: column.align
                                    }}
                                >
                                    {column.label}
                                </TableCellStyled>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCellStyled
                                    sx={{ border: "none", textAlign: "center" }}
                                    colSpan={columns?.length}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "200px",
                                        }}
                                    >
                                        <Loader />
                                    </Box>
                                </TableCellStyled>
                            </TableRow>
                        ) : (
                            <>
                                {!!data?.length &&
                                    data.map((row, rowIndex) => (
                                        <TableRowStyled
                                            key={row?.mainId || rowIndex}
                                            role="row"
                                            tabIndex={-1}
                                        >
                                            <TableCellStyled size="small" align="left">
                                                {row?.sNo}
                                            </TableCellStyled>
                                     
                                            <TableCellStyled size="small" align="left">
                                                {row?.date}
                                            </TableCellStyled>
                                            <TableCellStyled size="small" align="left">
                                                {row?.moduleType}
                                            </TableCellStyled>
                                            <TableCellStyled size="small" align="left">
                                                {row?.subModuleType}
                                            </TableCellStyled>
                                            <TableCellStyled size="small" align="left">
                                                {row?.actionType}
                                            </TableCellStyled>
                                            <TableCellStyled size="small" align="left">
                                                {row?.status}
                                            </TableCellStyled>
                                            <TableCellStyled className="centered-cell">
                                            {row?.File.length > 0 && (
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        textAlign: 'center',
                                                    }}>
                                                        <FaFolderOpen
                                                            className="fill-[555] hover:fill-red-650 me-1.5"
                                                            onClick={() => {
                                                                handleOpenModal(row?.mainId)
                                                                console.log("Modal Open:", isOpenModal, openModalFileId);
                                                            }}
                                                        />

                                                    </div>
                                                )}
                                            </TableCellStyled>

                                        </TableRowStyled>
                                    ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            { !!data?.length &&
                data.map((row, rowIndex) => ( 
                    (isOpenModal && openModalFileId === row?.mainId && (
                <FileModal
                    isOpen={true}
                    onClose={() => {
                        setIsOpenModal(false);
                        setOpenModalFileId(null);
                        console.log("Modal closed:", isOpenModal, openModalFileId);
                    }}
                    title="File Details"
                    tableData={row?.File}
                    userId={row?.userId}
                    userRole={row?.userRole}
                    userEmail={row?.userEmail}
                    headCells={headCells}
                    onCancel={() => {
                        setIsOpenModal(false);
                        setOpenModalFileId(null);
                        console.log("Modal closed:", isOpenModal, openModalFileId);
                    }}
                />
           ))) )}
            {!!pagination && data?.length > 0 && (
                <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
                    <ItemsPerPage
                        rowsPerPage={rowsPerPage}
                        onChange={handleChangeRowsPerPage}
                    />
                    <Pagination
                        totalCount={totalCount}
                        totalPages={totalPages}
                        start={start}
                        end={end}
                        currentPage={currentPage}
                        onChange={handleChangePage}
                    />
                </div>
            )}

        </Box>
    );
};

SocietyDownloadHistoryTable.propTypes = {
    columns: PropTypes.array.isRequired,
    defaultSortField: PropTypes.string,
    rowsPerPageOptions: PropTypes.array,
};

export default SocietyDownloadHistoryTable;
