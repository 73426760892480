import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DownloadExt, UploadEx } from "../../../assets";
import { MANAGE_FLATS_AUDIT_LOG_ENDPOINT, WINGS_AND_FLATS_ENDPOINT } from "../../../Routing/routes";
import Layout from "../../Layout/Layout";
import FlatTable from "./ManageFlatTable";
import UploadExcelModal from "../../../components/Modal/UploadExcelModal";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { downloadFlatExcel, getFlatDetails, uploadFlatsExcel } from "../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { useDispatch, useSelector } from "react-redux";
import { downloadExcel } from "../../../utils/helpers/downloadMedia";
import Loader from '../../../components/Loader/Loader'
import Button from "../../../components/Button/Button";
import { CircularProgress } from "@mui/material";
import * as permissions from '../../../Routing/permissions'
import { getPermission } from "../../../utils/helpers/getPermissions";

const ManageFlats = () => {
  const navigate = useNavigate();
  const { id } = useParams()
  const dispatch = useDispatch()
  const { loginData } = useSelector(state => state.loginReducer)
  const { downLoader } = useSelector(state => state.flatsDownloadExcelReducer)
  const { loading, uploadLoader, updateLoader, flatsData } = useSelector(state => state.wingsReducer)
  const { ipAddress } = useSelector(state => state.ipAddressReducer)

  const [file, setFile] = useState(null)
  const [onClickSave, setOnClickSave] = useState(undefined)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFile(null)
  };

  const handleDownloadExcel = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: 'flats_excel_sheet.xlsx' })
    }
    dispatch(downloadFlatExcel({ societyId: loginData?.default_community?.community_id, wingId: id, onSucessCallback }))
  }


  const onUpload = () => {
    let data = {
      wingId: id,
      societyId: loginData?.default_community?.community_id,
      ipAddress,
      file,
      sessionId: loginData?.session_id,
      onSuccessCallback: () => {
        dispatch(getFlatDetails(data))
      },
      onRequestEndCallback: () => {
        setFile(null)
        setIsModalOpen(false)
      }
    }
    dispatch(uploadFlatsExcel(data))
  }

  useEffect(() => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      wingId: id,
    }
    dispatch(getFlatDetails(data))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeaderBack
          onClick={handleNavigation(WINGS_AND_FLATS_ENDPOINT)}
          title={`Manage Flats ${!(loading && id !== flatsData?.wing?.wing_id?.toString()) ? `- ${flatsData?.wing?.wing_title}` : ''}`}
        >
          {downLoader ?
            <ButtonIco className=" w-32 justify-center" children={<CircularProgress sx={{ color: "#d32f2f" }} size={17} />} />
            :
            <ButtonIco icon={DownloadExt} children='Download Excel' onClick={handleDownloadExcel} />
          }
          {
            getPermission(loginData, [permissions.FLAT_EXCEL_UPLOAD_PERMISSION]) &&

            <button
              className="flex text-red-650 border border-red-650 bg-white text-xs h-8 px-2 font-semibold items-center whitespace-nowrap rounded-lg"
              onClick={openModal}
            >
              <UploadEx className="mr-2 fill-red-650" /> Upload Modified/New Flat
              Excel
            </button>
          }

          <div className="border-r"></div>
          <Button
            label={
              <span className="inline-block">Audit Log</span>
            }
            onClick={() => { navigate(`${MANAGE_FLATS_AUDIT_LOG_ENDPOINT}/${id}`) }}
            disabled={loading || updateLoader}
          />

          {
            getPermission(loginData, [permissions.ADD_FLATS_PERMISSION]) &&
            <>
              <div className="border-r"></div>
              <Button
                label={
                  <span className="w-8 inline-block">
                    {
                      updateLoader ?
                        <CircularProgress sx={{ color: "white" }} size={17} />
                        :
                        'Save'
                    }
                  </span>
                }
                type='submit' onClick={onClickSave}
                disabled={loading || updateLoader}
              />
            </>
          }

        </ListHeaderBack>
        <div className="border-b border-[#CCCCCC]"></div>
        <div>
          {
            loading && id !== flatsData?.wing?.wing_id?.toString() ?
              <Loader />
              :
              <FlatTable wingId={id} societyId={loginData?.default_community?.community_id} setOnClickSave={setOnClickSave} />
          }
        </div>
      </div>

      {/* Render the modal */}
      <UploadExcelModal
        isOpen={isModalOpen}
        onClose={closeModal}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        file={file}
        setFile={setFile}
        onUpload={onUpload}
        uploadLoader={uploadLoader}
        downloadSampleLink={`https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/billing-api/SampleExcels/flats_excel_sheet_sample.xlsx`}
        downloadFormatLink={''}

      />
    </Layout>
  );
};

export default ManageFlats;
