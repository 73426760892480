import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import { logOut } from '../../../../utils/helpers/logOut'
import getBrowserDetails from '../../../../utils/helpers/getBrowserDetails'
import { errorMsg } from '../../../../consts/responseMessages';

//wings
export async function getWingsAndFlatsDetailsReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_WINGS_AND_FLATS_API}?society_id=${action?.data?.societyId || ""}&society_type=${action?.data?.societyType || ""}&for_public_use=${action?.data?.forPublicUse || 0}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addWingsReq(action) {

    const BODY = {
        "society_id": action.data?.societyId,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ip": action.data?.ipAddress,
        "wings_data": action.data?.wings?.map((item) => ({
            "wing_id": item?.wingId || 0,
            'wing_no': item?.wingNo,
            'wing_title': item?.wingTitle,
            'no_of_floor': parseInt(item?.numberOfFloors),
            'no_of_unit': parseInt(item?.numberOfUnits)
        }))
    }

    return axiosInstance.post(apiEndpoints.ADD_WINGS_AND_FLATS_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteWingsReq(action) {

    const BODY = {
        "ip": action.data.ipAddress,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ids": [action.data?.wingId],
        "flag": "wing"
    }

    return axiosInstance.delete(apiEndpoints.DELETE_BUILDING_DETAILS_API, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getWingsAuditLogReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_WINGS_AUDIT_LOGS_API}?flag=wing&society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function downloadWingsExcelSheetReq(action) {

    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_WINGS_EXCEL_API}?society_id=${action.data?.societyId}`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

//flats
export async function getFlatsDetailsReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_FLATS_DETAILS_API}?flag=manage_flat&society_id=${action.data.societyId || ""}&wing_id=${action.data.wingId}&for_public_use=${action.data.forPublicUse || 0}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getFlatsAuditLogDetailsReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_FLATS_AUDIT_LOG_DETAILS_API}?society_id=${action.data.societyId || ""}&wing_id=${action.data.wingId}&flat_id=${action.data.flatId || ""}&audit_type=${action.data.auditType || ""}&from_date=${action.data.startDate || ""}&to_date=${action.data.endDate || ""}&page=${action.data.page}&limit=${action.data.limit}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function addFlatsReq(action) {

    const BODY = {
        "wing_id": action.data?.wingId,
        "society_id": action.data?.societyId,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ip": action.data?.ipAddress,
        "flats_data": action.data?.flats?.map((item) => ({
            "flat_id": item?.flatId,
            "flat_no": item?.flatNo,
            "area": item?.area,
            "floor_no": parseInt(item.numberOfFloors),
            "virtual_acc_no": item.virtualAccNo,
            "flat_status": item.flatStatus || ''
        }))
    }

    return axiosInstance.post(apiEndpoints.ADD_FLATS_DETAILS_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteFlatsReq(action) {

    const BODY = {
        "ip": action.data.ipAddress,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ids": [action.data?.flatId],
        "flag": "flat"
    }

    return axiosInstance.delete(apiEndpoints.DELETE_BUILDING_DETAILS_API, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function uploadFlatsReq(action) {

    var bodyFormData = new FormData();
    bodyFormData.append('wing_id', action.data?.wingId);
    bodyFormData.append('society_id', action.data?.societyId);
    bodyFormData.append('browser_details', getBrowserDetails());
    bodyFormData.append('session_id', action.data?.sessionId);
    bodyFormData.append('ip', action.data?.ipAddress);
    bodyFormData.append('file', action.data.file);

    return axiosInstance.post(apiEndpoints.UPLOAD_FLATS_DETAILS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function downloadFlatsExcelSheetReq(action) {

    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_FLATS_AND_BILL_TARIFF_EXCEL_API}?society_id=${action.data?.societyId}&wing_id=${action.data?.wingId}&flag=manage_flat`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

//bill tariff
export async function getBillTariffDetailsReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_BILL_TARIFF_API}?flag=bill_taxriff&society_id=${action.data.societyId}&wing_id=${action.data.wingId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function uploadBillTariffReq(action) {

    var bodyFormData = new FormData();
    bodyFormData.append('wing_id', action.data?.wingId);
    bodyFormData.append('society_id', action.data?.societyId);
    bodyFormData.append('browser_details', getBrowserDetails());
    bodyFormData.append('session_id', action.data?.sessionId);
    bodyFormData.append('ip', action.data?.ipAddress);
    bodyFormData.append('file', action.data.file);

    return axiosInstance.post(apiEndpoints.UPLOAD_BILL_TARIFF_DETAILS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateBillTariffReq(action) {

    const BODY = {
        "wing_id": action.data?.wingId,
        "society_id": action.data?.societyId,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ip": action.data?.ipAddress,
        "flats_data": action.data?.billTariff?.map((item) => ({
            "flat_id": item?.flatId,
            "flat_no": item?.flatNo,
            "floor_no": item?.floorNo,
            "water_inlets": parseInt(item?.waterInletes) || 0,
            "annual_property_tax": parseFloat(item?.annualPropertyTax) || 0,
            "annual_water_tax": parseFloat(item?.annualWaterTax) || 0,
            "annual_non_occupancy_tax": parseFloat(item?.annualOccupancyTax) || 0,
            "annual_other_tax": parseFloat(item?.annualOtherTax) || 0,
            "annual_other_1": parseFloat(item?.other1) || 0,
            "annual_other_2": parseFloat(item?.other2) || 0,
            "annual_other_3": parseFloat(item?.other3) || 0,
            "annual_other_4": parseFloat(item?.other4) || 0
        }))
    }
    return axiosInstance.post(apiEndpoints.UPDATE_BILL_TARIFF_DETAILS_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function downloadBillTariffExcelSheetReq(action) {

    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_FLATS_AND_BILL_TARIFF_EXCEL_API}?society_id=${action.data?.societyId}&wing_id=${action.data?.wingId}&flag=bill_taxriff`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

//utility reading
export async function getHeadNameListReq(action) {

    return axiosInstance.get(apiEndpoints.GET_HEAD_LIST_API)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getUtilityReadingReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_UTILITY_READINGS_DETAILS_API}?society_id=${action.data.societyId}&wing_id=${action.data.wingId}${action.data.headId ? `&head_id=${action.data.headId}` : ''}${action.data.month ? `&month=${action.data.month}` : ''}${action.data.year ? `&year=${action.data.year}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function updateUtilityReadingReq(action) {

    const BODY = {
        "wing_id": action.data?.wingId,
        "society_id": action.data?.societyId,
        "browser_details": getBrowserDetails(),
        "session_id": action.data?.sessionId,
        "ip": action.data?.ipAddress,
        "reading_data": action.data?.utilityReading?.map((item) => ({
            "reading_id": parseInt(item?.readingId) || 0,
            "fixed_charges": parseFloat(item?.fixedCharge) || 0,
            "last_month_reading": parseFloat(item?.lastMonthReading) || 0,
            "current_month_reading": parseFloat(item?.currentMonthReading) || 0,
            "month_in_no": parseInt(item?.month) || 0,
            "year_in_no": 0,
            "rate": parseFloat(item?.rate) || 0,
            "factor": parseFloat(item?.factor) || 1,
            "units": parseFloat(item?.units) || 0,
            "description": item?.description || "",
        }))
    }

    return axiosInstance.post(apiEndpoints.UPDATE_UTILITY_READINGS_DETAILS_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function uploadUtilityReadingReq(action) {

    var bodyFormData = new FormData();
    bodyFormData.append('wing_id', action.data?.wingId);
    bodyFormData.append('society_id', action.data?.societyId);
    bodyFormData.append('browser_details', getBrowserDetails());
    bodyFormData.append('session_id', action.data?.sessionId);
    bodyFormData.append('ip', action.data?.ipAddress);
    bodyFormData.append('file', action.data.file);

    return axiosInstance.post(apiEndpoints.UPLOAD_UTILITY_READINGS_DETAILS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

// generate invoice wings and flats dropdown with user name

export async function getFlatAndOccupantsDetailsReq(action) {

    return axiosInstance.get(`${apiEndpoints.GET_FLAT_LIST_OCCUPANTS_API}?society_id=${parseInt(action.data.societyId)}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                logOut()
            } else if (errors.response?.data.statusCode === 501) {
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}