import React, { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Layout from "../../../../Layout/Layout";
import ListHeaderBack from "../../../../../components/ListHeader/ListHeaderBack";
import Button from "../../../../../components/Button/Button";
import GenericTable from "../../../../../components/TableComponent/Table";
import { Box, Tooltip } from "@mui/material";
import { DeleteIcon, EditIcon, ViewIcon } from "../../../../../assets";
import {
  ADD_FAMILY_MEMBERS_ENDPOINT,
  ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT,
  EDIT_FAMILY_MEMBERS_ENDPOINT,
  VIEW_FAMILY_MEMBERS_ENDPOINT,
} from "../../../../../Routing/routes";
import UploadFamilyModal from "../../../../../components/Modal/UploadFamilyModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addFamilyMemberThroughExcel,
  deleteFamilyMember,
  downloadExcelSampleOfFamilyMember,
  getOccupantDetails,
  updateFamilyMemberThroughExcel,
} from "../../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import DeleteModal from "../../../../../components/Modal/DeleteModal";
import { downloadExcelFormat } from "../../../../../redux/actions/SetupRecordsActions/BulkUploadActions/BulkUploadAction";
import { downloadExcel } from "../../../../../utils/helpers/downloadMedia";

const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false, width: "70px" },
  { id: "roleAssign", label: "Role Assign", sort: true, width: "100px" },
  { id: "name", label: "Name", sort: true, width: "120px" },
  { id: "mobile", label: "Mobile", sort: true, width: "" },
  { id: "email", label: "Email", sort: true },
  { id: "gender", label: "Gender", sort: true },
  { id: "relationship", label: "Relationship", sort: true },
  {
    id: "action",
    label: "Action",
    sort: false,
  },
];

function ActionComponent({id}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subscribeId, flatId, userId } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    let data = {
      deleteFamilyId: id,
      flatId,
      searchText: searchParams.get("search") || "",
      subscribeId: subscribeId,
      societyId: loginData?.default_community?.community_id,
      userType: loginData?.user_type,
      userId: userId,
      ipAddress,
      sessionId: loginData?.session_id,
    };
    dispatch(deleteFamilyMember(data));
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="View">
        <ViewIcon
            className="fill-[#555] hover:fill-red-650 me-3"
            onClick={() => {navigate(`${VIEW_FAMILY_MEMBERS_ENDPOINT}/${subscribeId}/${userId}/${flatId}/${id}`)}}
        />
      </Tooltip>
      <Tooltip title="Edit" arrow>
        <EditIcon
          className="fill-[#555] hover:fill-red-650 me-3"
          onClick={() => {
            navigate(
              `${EDIT_FAMILY_MEMBERS_ENDPOINT}/${subscribeId}/${userId}/${flatId}/${id}`
            );
          }}
        />
      </Tooltip>
      <Tooltip title="Delete" arrow>
        <DeleteIcon
          className="fill-[#555] hover:fill-red-650"
          onClick={() => handleDeleteClick()}
        />
      </Tooltip>
      {/* Delete Confirmation Modal */}
      <DeleteModal
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
      />
    </Box>
  );
}

const FamilyMembersList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { subscribeId, userId, flatId } = useParams();
  const { loading, uploadLoader, occupantDetail } = useSelector(
    (state) => state.occupantInfoReducer
  );
  const { loginData } = useSelector((state) => state.loginReducer);
  const {ipAddress} = useSelector(state=>state.ipAddressReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const [isAddUploadModalOpen, setIsAddUploadModalOpen] = useState(false);
  const [isUpdateUploadModalOpen, setIsUpdateUploadModalOpen] = useState(false);
  const [addFile, setAddFile] = useState(null)
  const [updateFile, setUpdateFile] = useState(null)

  const handleDownloadFormatExcel = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: "Family Member Format.xlsx" });
    };
    const data = {
      societyId: loginData?.default_community?.community_id,
      flag: "family",
      onSucessCallback,
    };
    dispatch(downloadExcelFormat(data));
  };

  const handleDownloadSampleExcel = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: "Family Member Sample.xlsx" });
    };
    const data = {
      flatId,
      onSucessCallback,
    };
    dispatch(downloadExcelSampleOfFamilyMember(data));
  };


  const handleAddMemberThroughExcelExcel = () => {
    const onSuccessCallback = (data) => {
      dispatch(
        getOccupantDetails({
          subscribeId: subscribeId,
          societyId: loginData?.default_community?.community_id,
          userType: loginData?.user_type,
          userId: userId,
        })
      );
    };

    const onRequestEndCallback = ()=>{
      setIsAddUploadModalOpen(false)
      setAddFile(null)
    }
    const data = {
      flatId,
      onSuccessCallback,
      onRequestEndCallback,
      societyId: loginData?.default_community?.community_id,
      sessionId: loginData?.session_id,
      ipAddress,
      file: addFile
    };
    dispatch(addFamilyMemberThroughExcel(data));
  };

  const handleUpdateMemberThroughExcelExcel = () => {
    const onSuccessCallback = (data) => {
      dispatch(
        getOccupantDetails({
          subscribeId: subscribeId,
          societyId: loginData?.default_community?.community_id,
          userType: loginData?.user_type,
          userId: userId,
        })
      );
    };

    const onRequestEndCallback = ()=>{
      setIsUpdateUploadModalOpen(false)
      setUpdateFile(null)
    }
    const data = {
      flatId,
      onSuccessCallback,
      onRequestEndCallback,
      societyId: loginData?.default_community?.community_id,
      sessionId: loginData?.session_id,
      ipAddress,
      file: updateFile
    };
    dispatch(updateFamilyMemberThroughExcel(data));
  };


  useEffect(() => {
    dispatch(
      getOccupantDetails({
        subscribeId: subscribeId,
        societyId: loginData?.default_community?.community_id,
        userType: loginData?.user_type,
        userId: userId,
      })
    );
  }, [searchParams]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-3">
        <ListHeaderBack
          onClick={handleNavigation(-1)}
          title={
            occupantDetail &&
            `${
              occupantDetail?.user_detail?.prefix +
              ". " +
              occupantDetail?.user_detail?.f_name +
              " " +
              occupantDetail?.user_detail?.m_name +
              " " +
              occupantDetail?.user_detail?.l_name
            } >>>
                    ${
                      occupantDetail?.flat_data?.find(
                        (item) => item.flat_id?.toString() === flatId
                      )?.flat_no
                    }`
          }
        >
          <Button
            label="Bulk Upload Family"
            className="h-8"
            onClick={()=>{setIsAddUploadModalOpen(true)}}
          />
          <Button
            label="Add Family"
            className="h-8"
            onClick={handleNavigation(
              `${ADD_FAMILY_MEMBERS_ENDPOINT}/${subscribeId}/${userId}/${flatId}`
            )}
          />
          <span className="border-r border-[#CCCCCC] h-full"></span>
          <Button label="Archive Members" className="h-8"
            onClick={handleNavigation(ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT)}
           />
        </ListHeaderBack>

        <div className="border-b border-[#CCCCCC]"></div>
        <div className="px-1 py-2 flex flex-row justify-between gap-2">
          <div>

          </div>
          <div className="flex justify-between items-center">
            <div className="border-r border-[#CCC] mx-2 h-full"></div>
            <div className="flex gap-2">
              <Button
                label="Update Family Details"
                className="h-8"
                onClick={()=>{setIsUpdateUploadModalOpen(true)}}
              />
            </div>
          </div>
        </div>

        <div className="ml-1">
          <GenericTable
            loading={loading}
            data={occupantDetail?.flat_data
              ?.find((item) => item.flat_id?.toString() === flatId)
              ?.family_data?.map((item) => {
                return {
                  id: item?.id,
                  roleAssign: item?.user_type || '-',
                  name: item?.full_name || '-',
                  mobile: item?.contact_no || '-',
                  email: item?.email || '-',
                  gender: item?.gender || '-',
                  relationship: item?.relationship || '-',
                };
              })}
            columns={headCells}
            checkbox={false}
            pagination={
             false
            }
            currentPage={1}
            getTableData={(rowsPerPage, page) => {}}
          >
            <ActionComponent />
          </GenericTable>
        </div>

        {!occupantDetail?.flat_data?.find(
          (item) => item.flat_id?.toString() === flatId
        )?.family_data?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
      <UploadFamilyModal
        isOpen={isAddUploadModalOpen}
        onClose={()=>{
          setIsAddUploadModalOpen(false)
        }}
        title='Bulk Upload Family Member'
        file={addFile}
        setFile={setAddFile}
        uploadLoader={uploadLoader}
        onUpload={handleAddMemberThroughExcelExcel}
        onDownloadFormatClick={handleDownloadFormatExcel}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />

      {isUpdateUploadModalOpen && (
        <UploadFamilyModal
          isOpen={isUpdateUploadModalOpen}
          onClose={()=>{
            setIsUpdateUploadModalOpen(false)
          }}
          title='Update Family Details'
          file={updateFile}
          setFile={setUpdateFile}
          uploadLoader={uploadLoader}
          onUpload={handleUpdateMemberThroughExcelExcel}
          onDownloadSampleClick={handleDownloadSampleExcel}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      )}
    </Layout>
  );
};

export default FamilyMembersList;
