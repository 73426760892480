import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ListHeader from '../../../components/ListHeader/ListHeader';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../../Layout/Layout';
import SocietyDownloadHistoryTable from "./SocietyDownloadHistoryTable";
import { downloadUploadHistories } from "../../../redux/actions/ReportActions/SocietyReportActions";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import ButtonG from "../../../components/Button/ButtonG";
import { downloadUploadModuleTypes, downloadUploadStatusList } from "../../../consts/DropDownOptions";
import { SOCIETY_HISTORY_REPORT_ENDPOINT } from "../../../Routing/routes";
import moment  from 'moment';
import RangeDatePicker from '../../../components/DatePicker/RangeDatePicker';


export const headCells = [
    { id: "sNo", label: "Sr. No.", width: "50px" },
    // { id: "uuid", label: "Upload ID", width: "200px" },
    { id: "date", label: "Date", width: "90px" },
    { id: "Module Type", label: "Module Type", width: "70px" },
    { id: "Sub type", label: "Sub Type", sort: true, width: "120px" },
    { id: "Action Type", label: "Type", sort: true, width: "70px" },
    { id: "File status", label: "Status", sort: true, width: "70px" },
    { id: "User Action", label: "Action", sort: false, width: "40px" },
];
const SocietyDownloadHistory = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { loginData } = useSelector((state) => state.loginReducer);
    const navigate = useNavigate();
    const [selectedModule, setSelectedModule] = useState(null);
    const [subModuleOptions, setSubModuleOptions] = useState([]);

    const { societyDownloadHistory, loading } = useSelector((state) => state.societyReportReducer)
    console.log('societyDownloadHistory', societyDownloadHistory)
    useEffect(() => {
        dispatch(
            downloadUploadHistories({
                societyId: loginData?.default_community?.community_id,
                page: searchParams.get("page") || 1,
                limit: searchParams.get("pageLimit") || 10,
                moduleType: searchParams.get("module_type") || "",
                subModuleType: searchParams.get("sub_module_type") || "",
                startDate: searchParams.get("startDate") || "",
                endDate: searchParams.get("endDate") || "",
                status: searchParams.get('status') || ""
            })
        )

        const module = downloadUploadModuleTypes.find(mod => mod.value === searchParams.get('module_type'));
        if (module && module.subModules) {
            setSubModuleOptions(module.subModules);
        }
    }, [searchParams])


    const handleModuleChange = (selected) => {
        setSubModuleOptions([]);
        setSelectedModule(selected);

        searchParams.delete("page");
        searchParams.delete("module_type");
        searchParams.delete("sub_module_type");

        navigate({
          pathname: `${SOCIETY_HISTORY_REPORT_ENDPOINT}`,
          search: `?${createSearchParams([
            ...searchParams.entries(),
            ["module_type", selected],
          ])}`,
        });
        
        const module = downloadUploadModuleTypes.find(mod => mod.value === selected);
        if (module && module.subModules) {
            setSubModuleOptions(module.subModules);
        }
    };

    const handleSubModuleChange = (selected) => {
        searchParams.delete("page");
        searchParams.delete("sub_module_type");
        navigate({
            pathname: `${SOCIETY_HISTORY_REPORT_ENDPOINT}`,
            search: `?${createSearchParams([
              ...searchParams.entries(),
              ["sub_module_type", selected],
            ])}`,
          });
    }

    const handleStatus = (selected) => {
        searchParams.delete("page");
        searchParams.delete("status");
        navigate({
            pathname: `${SOCIETY_HISTORY_REPORT_ENDPOINT}`,
            search: `?${createSearchParams([
              ...searchParams.entries(),
              ["status", selected],
            ])}`,
          });
    }

    
    const handleStartDate = (selectedDate) => {
        if (
            moment(selectedDate).format("YYYY-MM-DD") !==
            searchParams.get("startDate")
        ) {
            searchParams.delete("page");
            searchParams.delete("pageLimit");
            searchParams.delete("startDate");
            navigate({
                pathname: `${SOCIETY_HISTORY_REPORT_ENDPOINT}`,
                search: `?${createSearchParams([
                    ...searchParams.entries(),
                    ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
                ])}`,
            });
        }
    };
    
    const handleEndDate = (selectedDate) => {
        if (
            moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
        ) {
            searchParams.delete("page");
            searchParams.delete("pageLimit");
            searchParams.delete("endDate");
            navigate({
                pathname: `${SOCIETY_HISTORY_REPORT_ENDPOINT}`,
                search: `?${createSearchParams([
                    ...searchParams.entries(),
                    ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
                ])}`,
            });
        }
    };


    const handleClear = () => {
        navigate(SOCIETY_HISTORY_REPORT_ENDPOINT);
    };

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] roundedF-lg p-2">
                <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Import Export History">
                    </ListHeader>
                </div>
                <div className="flex justify-between gap-2 py-1 my-1">
                    <div className="flex gap-4">
                        <Dropdown2     
                            options={
                                downloadUploadModuleTypes
                                  ? downloadUploadModuleTypes?.map(
                                      (item) => ({
                                        label: item?.value,
                                        value: item?.value,
                                      })
                                    )
                                  : []
                              }                   
                            placeholder="Module Type"
                            className="text-[11px] ms-4"
                            width="162px"
                            height="32px"     
                            onSelect={(selectedItem) => {
                                handleModuleChange(selectedItem.value);
                            }} 
                            value={
                                searchParams.get("module_type") && {
                                  value: searchParams.get("module_type"),
                                  label: downloadUploadModuleTypes?.find(
                                    (item) =>
                                      item.value?.toString() === searchParams.get("module_type")
                                  )?.value,
                                }
                              }                
                        />
                        <Dropdown2
                            options={
                                subModuleOptions
                                  ? subModuleOptions?.map(
                                      (item) => ({
                                        label: item?.value,
                                        value: item?.value,
                                      })
                                    )
                                  : []
                              }
                            placeholder="Sub Module Type"
                            className="text-[11px] ms-4"
                            width="162px"
                            height="32px"    
                            onSelect={(selectedItem) => {
                                handleSubModuleChange(selectedItem.value)
                            }} 
                            value={
                                searchParams.get("sub_module_type")
                                    ? {
                                        value: searchParams.get("sub_module_type"),
                                        label: subModuleOptions.find(
                                            (item) => item.value === searchParams.get("sub_module_type")
                                        )?.value,
                                    }
                                    : null
                            }                  
                        />

                        <Dropdown2
                            options={
                                downloadUploadStatusList
                                  ? downloadUploadStatusList?.map(
                                      (item) => ({
                                        label: item?.label,
                                        value: item?.value,
                                      })
                                    )
                                  : []
                              }
                            placeholder="Status"
                            className="text-[11px] ms-4"
                            width="162px"
                            height="32px"    
                            onSelect={(selectedItem) => {
                                handleStatus(selectedItem.value)
                            }} 
                            value={
                                searchParams.get("status")
                                    ? {
                                        value: searchParams.get("status"),
                                        label: downloadUploadStatusList.find(
                                            (item) => item.value === searchParams.get("status")
                                        )?.value,
                                    }
                                    : null
                            }                  
                        />


                        
                    </div>

                    <div class="flex items-center justify-between gap-3">
                            <RangeDatePicker
                                className={"w-[190px] h-[32px]"}
                                defaultStartValue={
                                searchParams.get("startDate")
                                    ? new Date(searchParams.get("startDate"))
                                    : ""
                                }
                                defaultEndValue={
                                searchParams.get("endDate")
                                    ? new Date(searchParams.get("endDate"))
                                    : ""
                                }
                                onStartDateChange={handleStartDate}
                                onEndDateChange={handleEndDate}
                            />
                            <ButtonG label="Clear" className="h-8" onClick={handleClear} />
                    </div>
                    
                </div>
                
                <SocietyDownloadHistoryTable
                    loading={loading}
                    data={societyDownloadHistory?.data?.map((item, index) => ({

                        sNo: (societyDownloadHistory?.meta?.current_page - 1) *
                            societyDownloadHistory?.meta?.per_page +
                            1 +
                            index,
                        mainId:item?.id ,
                        date: item?.created_at,
                        status: item?.status,
                        actionType: item?.action_type,
                        moduleType: item?.module_type,
                        subModuleType: item?.sub_module_type,
                        File: item?.files || [],
                        errorDetails: item?.error_details,
                        metaData: item?.metadata,
                        userId : item?.user_id,
                        userRole : item?.user_role,
                        userEmail : item?.user_email
                    }))}
                    columns={headCells}
                    checkbox={false}
                    pagination={societyDownloadHistory?.data?.length}
                    totalCount={societyDownloadHistory?.meta?.total}
                    totalPages={societyDownloadHistory?.meta?.last_page}
                    start={(societyDownloadHistory?.meta?.current_page -1) * societyDownloadHistory?.meta?.per_page + 1}
                    end={(societyDownloadHistory?.meta?.current_page -1) * societyDownloadHistory?.meta?.per_page + societyDownloadHistory?.data?.length}
                    currentPage={Number(societyDownloadHistory?.meta?.current_page)}
                    rowsPerPage={searchParams.get("pageLimit") || 10}
                />
                {!societyDownloadHistory?.data?.length && !loading && (
                    <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                        No Records
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default SocietyDownloadHistory;