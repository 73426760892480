import React, { useState } from "react";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import Dropdown2 from "../../../../../components/Dropdown/dropdown2";
const frequency = [
  {
    "value" : -1,
    "label" : "Daily",
  }, 
  {
    "value" : 1,
    "label" : "Monthly",
  }, 
  {
    "value" : 2,
    "label" : "Bi-Monthly"
  },
  {
    "value" : 3,
    "label" : "Quarterly"
  },
  {
    "value" : 6,
    "label" : "Semi-Anually"
  },
  {
    "value" : 12,
    "label" : "Anually"
  },
]

const FunctionalSettings = ({
  invoiceSettingList,
  setInvoiceSettingList
}) => {
  return (
    <div>
      <div className="mt-2">
        <table className="border-separate w-[55%]">
          <tbody>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Frequency</td>
              <td className="pb-4">
                <Dropdown2
                  options={frequency}
                  placeholder="Select Frequency"
                  className=""
                  width="160px"
                  height="36px"
                  value={invoiceSettingList?.bill_duration && invoiceSettingList.bill_duration!=''  ? {
                    label: frequency?.find((detail) => parseInt(detail.value) === parseInt(invoiceSettingList?.bill_duration)).label,
                    value: parseInt(invoiceSettingList?.bill_duration)
                  } : null}
                  onSelect={(selectedItem) => {
                    setInvoiceSettingList({...invoiceSettingList, bill_duration: selectedItem.value });
                  }}
                  name={"freq"}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Update Notes For All Invoice</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.updthdrftr==="Yes"?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, updthdrftr: e.target.checked ? "Yes" : "No" });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Hide Zero Value Items </td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.hideitem==="Yes"?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, hideitem: e.target.checked ? "Yes" : "No" });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td>Enable Round Off feature</td>
              <td>
                <Checkbox
                  text="Yes"
                  disabled={true}
                  className="text-sm"
                  initialchecked={invoiceSettingList.roundoff==="Yes"?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, roundoff: e.target.checked ? "Yes" : "No" });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center text-sm">
              <td className="pb-4">Show Receipt With Invoice </td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.showReceiptInInvoice==="Yes"?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, showReceiptInInvoice: e.target.checked ? "Yes" : "No" });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4"> Show Counter Foil (below footer image)</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.showCounterFoil==="Yes"?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, showCounterFoil: e.target.checked ? "Yes" : "No" });
                  }}
                />
              </td>
            </tr>
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Show Advance Receipt With Invoice</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.showExcessPaid==="Yes"?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, showExcessPaid: e.target.checked ? "Yes" : "No" });
                  }}
                />
              </td>
            </tr>
            {/* <tr className="items-center mb-4 text-sm">
              <td className="pb-4">Show Amount In Decimals Up To 2 Digits</td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={checked}
                  onChange={(e) => {
                    handleChecked();
                  }}
                />
              </td>
            </tr> */}
            {/* <tr className="items-center mb-4 text-sm">
              <td className="pb-4">
                Restrict Facility Booking For Non Payment Of Bill Due
              </td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.restrictFacilityBooking==='1'?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, restrictFacilityBooking: e.target.checked ? '1' : "0" });
                  }}
                />
              </td>
            </tr> */}
            <tr className="items-center mb-4 text-sm">
              <td className="pb-4">
                Proforma Invoice
              </td>
              <td className="pb-4">
                <Checkbox
                  text="Yes"
                  className="text-sm"
                  initialchecked={invoiceSettingList.proforma==='Yes'?true:false}
                  onChange={(e) => {
                    setInvoiceSettingList({...invoiceSettingList, proforma: e.target.checked ? 'Yes' : "No" });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {/* <hr className="fill-[#CCC]" />
        <div className="flex justify-between items-center w-[55%] p-2">
          <div className="font-semibold">Taxable Invoice</div>
          <div className="-ml-4">
            <Checkbox
              text="Yes"
              className="text-sm"
              initialchecked={checked}
              onChange={(e) => {
                handleChecked();
              }}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default FunctionalSettings;
