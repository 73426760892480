import { Subject } from "@mui/icons-material";
import * as Yup from "yup";

// log a ticket
export const logaTicketValidation = () => {
  return Yup.object({
    complaintNature: Yup.string().required("Complaint Nature is required"),
    complaintType: Yup.string().required("Complaint Type is required"),
    category: Yup.string().required("Category is required"),
    subCategory: Yup.string().required("SubCategory is required"),
    isUrgent: Yup.string(),
    Subject: Yup.string(),
    description: Yup.string().required("Description is required"),
    wingId: Yup.string().required("Wing is Required"),
    flatsNo: Yup.string().required("Flat is required"),
    attachments: Yup.string(),
  });
};

//Add Folder
export const FolderNameValidation = () => {
  return Yup.object().shape({
    folderName: Yup.string().required("*Folder Name is required"),
  });
};

//sub Folder
export const SubFolderNameValidation = () => {
  return Yup.object().shape({
    folderId: Yup.string().required("*Folder Name is required"),
    subFolderName: Yup.string().required("*Sub Folder Name is Required"),
  });
};

//edit complaint escalation settings
export const updateComplaintEscalationSettingsValidation = () => {
  return Yup.object({
    escalationlevel: Yup.string().required("*Level is required"),
    duration: Yup.number().typeError("Type Number is required"),
  });
};

//upload document
export const UploadDocumentValidation = () => {
  return Yup.object().shape({
    fileId:Yup.string(),
    folderId: Yup.string().required("*Folder is required"),
    title: Yup.string().required("*Title is required"),
    file: Yup.string(),
  });
};

// Assignmentof complaint
export const AssignmentComplaintValidation = () => {
  return Yup.object().shape({
    servicedBy: Yup.string(),
    writeComment: Yup.string(),
    emailAssignee: Yup.string(),
    emailOwner: Yup.string(),
    reqRole: Yup.string()
    .when(['reAssign'], (reAssign, schema) => {
      return reAssign[0] === 'employee' ?
      schema.required("*Required")
      :
      schema
    }),
    
    reqSubRole: Yup.string()
    .when(['reAssign'], (reAssign, schema) => {
      return reAssign[0] === 'employee' ?
      schema.required("*Required")
      :
      schema
    }),
    assignedMember: Yup.string()
    .when(['reAssign'], (reAssign, schema) => {
      return reAssign[0] === 'employee' ?
      schema.required("*Required")
      :
      schema
    }),
    societyName: Yup.string()
    .when(['reAssign'], (reAssign, schema) => {
      return reAssign[0] === 'society' ?
      schema.required("*Required")
      :
      schema
    }),
    
    wingId: Yup.string()
    .when(['reAssign'], (reAssign, schema) => {
      return reAssign[0] === 'society' ?
      schema.required("*Required")
      :
      schema
    }),
    flatsNo: Yup.string()
    .when(['reAssign'], (reAssign, schema) => {
      return reAssign[0] === 'society' ?
      schema.required("*Required")
      :
      schema
    }),
    reAssign: Yup.string(),
  });
};

// post comment 

export const PostCommentValidation = () => {
  return Yup.object().shape({
    status: Yup.string(),
    comment: Yup.string().required('Comment is required'),
    emailAssignee: Yup.number(),
    emailOwner: Yup.number(),
    cc: Yup.string(),
    bcc: Yup.string()
  })
}

export const AddComplaintCategoryValidation = () => {
  return Yup.object().shape({
    title: Yup.string().required('Category is required'),
    subCategory: Yup.array()
    .of(
      Yup.object().shape({
        subTitle: Yup.string().required('SubCategory is required'),
        subMessageTemplate: Yup.string().required('Template is required'),
      })
    ),
    msgTemplate: Yup.string(),
  })
}

export const UpdateComplaintSettingValidation = () => {
  return Yup.object().shape({
    categories: Yup.array()
    .of(
      Yup.object().shape({
        level1Committee: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            title: Yup.string(),
            isSelected: Yup.string(),
          })
        ),
        level2Committee: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            title: Yup.string(),
            isSelected: Yup.string(),
          })
        ),
        level3Committee: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            title: Yup.string(),
            isSelected: Yup.string(),
          })
        ),
        level1Agency: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            title: Yup.string(),
            isSelected: Yup.string(),
          })
        ),
        level2Agency: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            title: Yup.string(),
            isSelected: Yup.string(),
          })
        ),
        level3Agency: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            title: Yup.string(),
            isSelected: Yup.string(),
          })
        ),
        level1Employee: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            title: Yup.string(),
            isSelected: Yup.string(),
          })
        ),
        level2Employee: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            title: Yup.string(),
            isSelected: Yup.string(),
          })
        ),
        level3Employee: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            title: Yup.string(),
            isSelected: Yup.string(),
          })
        ),
      })
    )
  })
}


export const editComplaintValidation = () => {
  return Yup.object({
    complaintNature: Yup.string().required("Complaint Nature is required"),
    complaintType: Yup.string().required("Complaint Type is required"),
    category: Yup.string().required("Category is required"),
    subCategory: Yup.string().required("SubCategory is required"),
    isUrgent: Yup.string(),
    description: Yup.string().required("Description is required"),
    // isTenant: Yup.string().required("required"),
    wingId: Yup.string().required("Wing is Required"),
    flatsNo: Yup.string().required("Flat is required"),
    attachments: Yup.string(),
  });
};