import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/ReportsReqs/SocietyReportsReqs/SocietyReportsReqs'
import { notify } from '../../../utils/notification';

function* getSocietyEmailReports(action) {
    try {
        const result = yield call(req.getSocietyEmailReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_SOCIETY_EMAIL_REPORTS_SUCCESS, payload: result })
        } else {
            yield put({ type: types.GET_SOCIETY_EMAIL_REPORTS_FAILURE, message: result?.response })
        }
    } catch (e) {
        console.log(e)
    }
}

function* getMemberLedgerReports(action) {
    try {
        const result = yield call(req.getMemberLedgerReportReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_MEMBER_LEDGER_REPORTS_SUCCESS, payload: result.data })
        } else {
            yield put({ type: types.GET_MEMBER_LEDGER_REPORTS_FAILURE, message: result?.response })
        }
    } catch (e) {
        console.log(e)
    }
}

function* getSocietyParkingReports(action) {
    try {
        const result = yield call(req.getSocietyParkingReportReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_SOCIETY_PARKING_REPORTS_SUCCESS, payload: result.data })
        } else {
            yield put({ type: types.GET_SOCIETY_PARKING_REPORTS_FAILURE, message: result?.response })
        }
    } catch (e) {
        console.log(e)
    }
}


function* downloadSocietyEmailReports(action) {
    try {
        const result = yield call(req.downloadSocietyEmailReportReq, action);
        if (result.success === true) {
            action.data?.onSuccessCallback(result.download_url)
            action.data.download_url = result.download_url;
            yield put({ type: types.DOWNLOAD_SOCIETY_EMAIL_REPORTS_SUCCESS });
            yield call(req.deleteSocietyReportExcelReq, action);
        } else {
            yield put({ type: types.DOWNLOAD_SOCIETY_EMAIL_REPORTS_FAILURE });
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}

function* downloadNDC(action) {
    try {
        const result = yield call(req.downloadNDC, action);
        if (result.success === true) {
            yield put({ type: types.DOWNLOAD_NDC_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.DOWNLOAD_NDC_FAILURE });
            notify('Something Wrong')
        }
    } catch (e) {
        console.log(e)
    }
}

function* getAppDownloadReports(action) {
    try {
        const result = yield call(req.getAppDownloadReportsReq, action);
        if (result?.statusCode === 200) {
            yield put({ type: types.GET_APP_DOWNLOAD_REPORTS_SUCCESS, payload: result?.data })
        } else {
            yield put({ type: types.GET_APP_DOWNLOAD_REPORTS_FAILURE, message: result?.response })
        }
    } catch (e) {
        console.log(e)
    }
}

function* appDownloadReportsXl(action) {
    try {
        const result = yield call(req.appDownloadReportsXlReq, action);
        if (result instanceof ArrayBuffer) {
            yield put({ type: types.APP_DOWNLOAD_REPORTS_XL_SUCCESS});
            action.data?.onSucessCallback(result)
        } else {
            yield put({ type: types.APP_DOWNLOAD_REPORTS_XL_FAILURE});
            notify('Unable to download sheet.')
        }
    } catch (e) {
    }
}

function* downloadUploadHistories(action) {
    try {
        const result = yield call(req.getDownloadUploadHistoriesReq, action);
        if (result.success === true) {
            yield put({ type: types.GET_DOWNLOAD_UPLOAD_HISTORIES_SUCCESS, payload: result });
        } else {
            yield put({ type: types.GET_DOWNLOAD_UPLOAD_HISTORIES_FAILURE, message: result?.response?.data?.message });
            notify('Unable to download sheet.')
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* societyReportSaga() {
    yield takeLatest(types.GET_SOCIETY_EMAIL_REPORTS, getSocietyEmailReports);
    yield takeLatest(types.DOWNLOAD_SOCIETY_EMAIL_REPORTS, downloadSocietyEmailReports);
    yield takeLatest(types.GET_APP_DOWNLOAD_REPORTS, getAppDownloadReports);
    yield takeLatest(types.APP_DOWNLOAD_REPORTS_XL, appDownloadReportsXl);
    yield takeLatest(types.GET_MEMBER_LEDGER_REPORTS, getMemberLedgerReports);
    yield takeLatest(types.DOWNLOAD_NDC, downloadNDC);
    yield takeLatest(types.GET_SOCIETY_PARKING_REPORTS, getSocietyParkingReports);
    yield takeLatest(types.GET_DOWNLOAD_UPLOAD_HISTORIES, downloadUploadHistories);

}